import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatMenuModule} from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button'
// NGXS
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { environment } from 'src/environments/environment';
import { LoginState } from './store/login/login.state';
import { ProductState } from './store/products/products.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AnalysisState } from './store/analysis/analysis.state';
import { SignupState } from './store/signup/signup.state';
import { AppWithHeaderComponent } from './app-with-header/app-with-header.component';
import { SpinnerComponent } from "./common-components/spinner/spinner.component";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SuccessMessageComponent } from './messages/success-message/success-message.component';
import { ErrorMessageComponent } from './messages/error-message/error-message.component';
import { MatDialogModule } from '@angular/material/dialog';
import mixpanel from 'mixpanel-browser';

mixpanel.init(environment.mixpanelKey)

//states
const angularMaterialImports = [
    MatDialogModule,
    MatExpansionModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatRadioModule,
  ];

@NgModule({
    declarations: [
        AppComponent,
        AppWithHeaderComponent,
        SuccessMessageComponent,
        ErrorMessageComponent,
        
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: 'googleTagManagerId', useValue: environment.googleTagID },
        GoogleTagManagerService
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ...angularMaterialImports,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxsModule.forRoot([
            LoginState,
            ProductState,
            AnalysisState,
            SignupState,
            ProductState,
        ], { developmentMode: !environment.production }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        NgxsStoragePluginModule.forRoot({
            key: ['auth.access', 'auth.refresh', 'auth.tokenBody'],
        }),
        AngularFireAuthModule,
        SpinnerComponent
    ]
})
export class AppModule { }
