import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppWithHeaderComponent } from './app-with-header/app-with-header.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/guards/auth.guard';
import { UnAuthedGuard } from './core/guards/unauthed.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full',
  },
  {
    path: 'signup',
    loadChildren: () =>
    import('./signup/signup.module').then(m => m.SignupModule),
    canLoad: [UnAuthedGuard],
    canActivate: [UnAuthedGuard]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
      canLoad: [UnAuthedGuard],
      canActivate: [UnAuthedGuard]
  },
  {
    path: 'signup',
    loadChildren: () =>
    import('./signup/signup.module').then(m => m.SignupModule),
    canLoad: [UnAuthedGuard],
    canActivate: [UnAuthedGuard]
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('./ecommerce-redirect/ecommerce-redirect.module').then((m) => m.EcommerceRedirectModule),
  },
  {
    path: '',
    component:AppWithHeaderComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'favorites',
        loadChildren: () =>
          import('./favorites/favorites.module').then((m) => m.FavoritesModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'analytics',
        loadChildren: () =>
          import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
          canLoad: [AuthGuard],
          canActivate: [AuthGuard]
      },
      {
        path: 'product/:productId',
        loadChildren: () =>
          import('./single-product/single-product.module').then((m) => m.SingleProductModule),
          canLoad: [AuthGuard],
          canActivate: [AuthGuard]
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
exports: [RouterModule],
})
export class AppRoutingModule { }
