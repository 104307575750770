import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Products } from './products.action';
import { Injectable } from '@angular/core';
import { Product } from '../../shared/models/products/products';
import { ProductsService } from './../../shared/services/products/products.service';
import { tap } from 'rxjs';
import { Category } from 'src/app/shared/models/products/products';

export interface ProductsSateModel {
  products: Product[] | null;
  product: Product | null;
  categories : Category[] | null;
}

@State<ProductsSateModel>({
  name: 'ProductsSate',
  defaults: {
    products: [],
    product: null,
    categories: [],
  },
})


@Injectable()
export class ProductState {
  constructor(private productsService: ProductsService) {}

  @Selector()
  static getProducts(state: ProductsSateModel) {
    return state.products;
  }

  @Selector()
  static getProduct(state: ProductsSateModel) {
    return state.product;
  }

  static getCategories(state: ProductsSateModel) {
    return state.categories;
  }

  @Action(Products.FetchAll)
  get(ctx: StateContext<ProductsSateModel>) {
    return this.productsService.fetchAll().pipe(
      tap((res : any) => {
        ctx.patchState({
          products: res.products,
        });
      })
    );
  }

  @Action(Products.GetProduct)
  getProduct(ctx: StateContext<ProductsSateModel>, { id }: Products.GetProduct) {
    return this.productsService.getProduct(id).pipe(
      tap((res : any) => {
        ctx.patchState({
          product: res.product,
        });
      })
    );
  }

  @Action(Products.FetchAllCategories)
  getCategories(ctx: StateContext<ProductsSateModel>) {
    return this.productsService.fetchAllCategories().pipe(
      tap((res : any) => {
        ctx.patchState({
          categories: res.categories,
        });
      })
    );
  }
}
