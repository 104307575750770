import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  /**
   * Initialize mixpanel.
   * @memberof MixpanelService
   */
  init(key: string): void {
    mixpanel.init(key);
  }
  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
  identify(id: string): void {
    mixpanel.identify(id);
  }
  set( values: any = {}): void {
    mixpanel.people.set(values);
  }
  increment(PropertyName: string): void {
    mixpanel.people.increment(PropertyName);
  }
  incrementBy(PropertyName: string,value : number): void {
    mixpanel.people.increment(PropertyName,value);
  }
}
