export namespace Products {
  export class FetchAll {
    static readonly type = '[PRODUCTS] Fetch All';
  }

  export class GetProduct {
    static readonly type = '[Product] Fetch';
    constructor(public id: number) {}
  }


  export class FetchAllCategories {
    static readonly type = '[PRODUCTS-CATEGORIES] Fetch All categories';
  }
}
