import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { LoginState } from 'src/app/store/login/login.state';
import { LogoutAction } from '../store/login/login.action';

@Component({
  selector: 'app-app-with-header',
  templateUrl: './app-with-header.component.html',
  styleUrls: ['./app-with-header.component.scss']
})
export class AppWithHeaderComponent {
  @ViewChild('sideNav') sideNav: MatSidenav | undefined;
  public isDesktop: boolean = true;
  public viewWidth: number = 0;
  public active = true;
  public userName: string = "default userName";
  public products_active=true;
  public favorites_active = false;
  public analytics_active = false;


  constructor(
    private store: Store,
    private matIconRegistry:MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public router: Router){
      matIconRegistry.addSvgIcon('products', this.domSanitizer.bypassSecurityTrustResourceUrl("../../../assets/icons/Product-icon.svg"))
      matIconRegistry.addSvgIcon('favorites', this.domSanitizer.bypassSecurityTrustResourceUrl("../../../assets/icons/Heart-icon.svg"))
      matIconRegistry.addSvgIcon('analytics', this.domSanitizer.bypassSecurityTrustResourceUrl("../../../assets/icons/Analytics-icon.svg"))
      matIconRegistry.addSvgIcon('side-nav-bar', this.domSanitizer.bypassSecurityTrustResourceUrl("../../../assets/icons/side-nav-bar-icon.svg"))
      matIconRegistry.addSvgIcon('down-arrow', this.domSanitizer.bypassSecurityTrustResourceUrl("../../../assets/icons/down-arrow.svg"))
      matIconRegistry.addSvgIcon('logout', this.domSanitizer.bypassSecurityTrustResourceUrl("../../../assets/icons/Logout-icon.svg"))
      matIconRegistry.addSvgIcon('analytics-after-click-sidebar', this.domSanitizer.bypassSecurityTrustResourceUrl("../../../assets/icons/Analytics-icon-after-click-sidebar.svg"))
      matIconRegistry.addSvgIcon('products-after-click-sidebar', this.domSanitizer.bypassSecurityTrustResourceUrl("../../../assets/icons/Product-icon-after-click-sidebar.svg"))

      const tokenBody = this.store.selectSnapshot(LoginState.getTokenBody);
      if(tokenBody)
        this.userName = tokenBody?.first_name + " "+ tokenBody?.last_name;

    }
  ngOnInit(): void {
    this.viewWidth = window.innerWidth;
    if (this.viewWidth > 770) {
      this.isDesktop = true;
    } else {
      this.isDesktop = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.viewWidth = event.target.innerWidth;
    this.isDesktop = false;
    if (this.viewWidth > 770) {
      this.isDesktop = true;
    }
  }
  resetPosition() {
    const elm: any = document.getElementById('page-container');
    if (elm) {
      elm.scrollTop = 0;
      this.active = true;
    }
  }

  toggle() {
    this.active = !this.active;
  }
  logout(){
    this.store.dispatch(new LogoutAction())
    .subscribe((_) => {
      this.router.navigate(['/login']);
    });

  }

}
