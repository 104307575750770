import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginRequest, SignResponse } from '../../models/sign.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }



  authinticateCreator(payload: LoginRequest): Observable<HttpResponse<SignResponse>> {
    return this.http.post<SignResponse>(
      environment.baseUrl + 'base/auth/',
      payload,
      { observe: 'response' }
    );
  }

  refreshAccess(refreshToken: string): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.baseUrl + 'base/auth/refresh/',
      { 'refresh': refreshToken },
      { observe: 'response' }
    );
  }
}
