import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SignResponse, SignupRequest } from '../../models/sign.model';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(private http: HttpClient) { }
  signup(payload: SignupRequest): Observable<HttpResponse<SignResponse>> {
    return this.http.post<SignResponse>(
      environment.baseUrl + 'base/sign-up/',
      payload,
      { observe: 'response' }
    );
  }
}
