import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule],
templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit{
  showSpinner = true;

  constructor(private spinnerService :SpinnerService , private cdRef : ChangeDetectorRef){

  }
  ngOnInit(): void {
    this.init()
  }

  init(){
    this.spinnerService.getSpinnerObserver().subscribe((status)=>{
      this.showSpinner = status === 'start'
      this.cdRef.detectChanges();
    })
  }
}
