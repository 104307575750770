import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Analysis,
  AnalysisPerProduct,
  AnalysisStatistics,
  CreateAnalysisRequest,
} from '../../models/analysis/analysis.models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalysisService {
  constructor(private http: HttpClient) {}

  create(
    analysisRequest: CreateAnalysisRequest
  ): Observable<HttpResponse<Analysis>> {
    return this.http.post<Analysis>(
      environment.baseUrl + 'base/analysis/',
      analysisRequest,
      { observe: 'response' }
    );
  }

  getStatistics(): Observable<AnalysisStatistics> {
    return this.http.get<AnalysisStatistics>(
      environment.baseUrl + 'base/analysis/statistics/'
    );
  }

  getAnalysisPerProduct(): Observable<AnalysisPerProduct[]> {
    return this.http.get<AnalysisPerProduct[]>(
      environment.baseUrl + 'base/analysis/'
    );
  }
}
