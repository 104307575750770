import { State, Action, StateContext, Selector } from '@ngxs/store';
import { AnalysisActions } from './analysis.actions';
import { Injectable } from '@angular/core';
import { Product } from '../../shared/models/products/products';
import { ProductsService } from './../../shared/services/products/products.service';
import { tap } from 'rxjs';
import { AnalysisPerProduct, AnalysisStatistics } from 'src/app/shared/models/analysis/analysis.models';
import { AnalysisService } from 'src/app/shared/services/analysis/analysis.service';

export interface AnalysisStateModel {
  analysisPerProduct: AnalysisPerProduct[];
  statistics: AnalysisStatistics
}

@State<AnalysisStateModel>({
  name: 'AnalysisSate',
  defaults: {
    analysisPerProduct: [],
    statistics: {total_clicks: 0, total_orders: 0}
  },
})

@Injectable()
export class AnalysisState {
  constructor(private analysisService: AnalysisService) {}

  @Action(AnalysisActions.getStatistics)
  getStatistics(ctx: StateContext<AnalysisStateModel>) {
    const state = ctx.getState();
    return this.analysisService.getStatistics().pipe(
      tap((statistics: AnalysisStatistics) => {
        ctx.patchState({
          statistics: statistics,
        });
      })
    );
  }

  @Action(AnalysisActions.getAnalysisPerProduct)
  getAnalysisPerProduct(ctx: StateContext<AnalysisStateModel>) {
    const state = ctx.getState();
    return this.analysisService.getAnalysisPerProduct().pipe(
      tap((analysis: any) => {
        ctx.patchState({
          analysisPerProduct: analysis.analysis,
        });
      })
    );
  }
}
