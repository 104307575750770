import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResetPasswordPayload } from '../../models/auth.models';
import { OTPRequest } from '../../models/sign.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public http: HttpClient
  ) { }


  registerFirebaseAuthedUser(registerRequest: any): Observable<HttpResponse<any>> {
    let url = `${environment.baseUrl}base/auth/`;
    let headers: HttpHeaders = new HttpHeaders().append("noauth", "true");
    return this.http.post<any>(url, registerRequest, {observe: 'response', headers: headers});
  }

  submitOTP(otpRequest: OTPRequest): Observable<HttpResponse<any>> {
    let url = `${environment.baseUrl}base/submit-otp/`;
    let headers: HttpHeaders = new HttpHeaders().append("noauth", "true");
    return this.http.post<any>(url, otpRequest, {observe: 'response', headers: headers });
  }

  resendOTP(phoneNumber: string, scope: string): Observable<HttpResponse<any>> {
    let url = `${environment.baseUrl}base/resend-otp/`;
    let headers: HttpHeaders = new HttpHeaders().append("noauth", "true");
    const requestBody = {
      phone_number: phoneNumber,
      scope: scope
    };
    return this.http.post<any>(url, requestBody, { observe: 'response', headers });
  }

  resetPassword(resetPasswordPayload: ResetPasswordPayload): Observable<HttpResponse<any>> {
    let url = `${environment.baseUrl}base/reset-password/`;
    let headers: HttpHeaders = new HttpHeaders().append("noauth", "true");
    return this.http.post<any>(url, resetPasswordPayload, {observe: 'response', headers });
  }
}
