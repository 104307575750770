import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Product } from '../../models/products/products';

import { Observable } from 'rxjs';
import { Category } from 'src/app/shared/models/products/products';
@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient) {}

  fetchAll() :Observable<Product[]> {
    return this.http.get<Product[]>(environment.baseUrl + 'products');
  }


  getProduct(id:number) :Observable<Product> {
    return this.http.get<Product>(environment.baseUrl + `products/${id}`);
  }

  fetchAllCategories() :Observable<Category[]> {
    return this.http.get<Category[]>(environment.baseUrl + 'products/categories');
  }

}
