import { SignupRequest } from "src/app/shared/models/sign.model";

export enum SignupActionTypes{
  SignupActionTypes="[Signup] singup creator",
}


export class SignupAction {
  public static readonly type = SignupActionTypes.SignupActionTypes;
  constructor(public payload: SignupRequest) {}
}
