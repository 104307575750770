import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit {
  errorCode: string;
  errorMessage: string;
  // Icons array
  icons: string[] = ['download'];

  constructor(
    private dialogRef: MatDialogRef<ErrorMessageComponent>,
    private sanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public key: any
  ) {
    this.addIcons();
  }

  ngOnInit(): void {
    this.errorMessage = this.key.request;
    this.errorCode = this.key.errorCode;
  }

  addIcons(): void {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `../../../assets/icons/${icon}.svg`
        )
      );
    }
  }

  closeModal() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  closureListenter() {
    this.dialogRef.afterClosed().subscribe((_) => {
      // this.dialogRef = null;
    });
  }
}
