import { TokenBody } from 'src/app/shared/models/sign.model';
import { State} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
export interface SignupStateModel {
  access: string | null | undefined;
  refresh: string | null | undefined;
  tokenBody: TokenBody | null | undefined;
}

@State<SignupStateModel>({
  name: 'Signup_auth',
  defaults: {
    access: '',
    refresh: '',
    tokenBody: null,
  },
})

@Injectable()
export class SignupState {
  constructor(
    public authService: AuthService,
  ) { }


 
}
