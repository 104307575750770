import {
  LoginAction,
  LoginRegisteredUser,
  RefreshTokenAction,
  LogoutAction,
  AuthorizeOTPSubmissionRequest as AuthorizeOTPSubmissionRequest,
} from './login.action';
import { SignResponse, TokenBody } from 'src/app/shared/models/sign.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { tap, catchError } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SignupAction } from '../signup/signup.action';
import { SignupService } from 'src/app/shared/services/signup/signup.service';
import { HttpResponse } from '@angular/common/http';
import { MixpanelService } from 'src/app/shared/services/mixpanel/mixpanel.service';
export interface LoginStateModel {
  access: string | null | undefined;
  refresh: string | null | undefined;
  tokenBody: TokenBody | null | undefined;
}

@State<LoginStateModel>({
  name: 'auth',
  defaults: {
    access: '',
    refresh: '',
    tokenBody: null,
  },
})
@Injectable()
export class LoginState {
  constructor(
    private loginService: LoginService,
    private signupService: SignupService,
    public authService: AuthService,
    private mixpanelService: MixpanelService
  ) {}

  @Selector()
  public static getAccessToken(state: LoginStateModel) {
    return state.access;
  }

  @Selector()
  public static getRefreshToken(state: LoginStateModel) {
    return state.refresh;
  }

  @Selector()
  public static getTokenBody(state: LoginStateModel) {
    return state.tokenBody;
  }

  @Action(LoginAction)
  public authenticate(
    ctx: StateContext<LoginStateModel>,
    { payload }: LoginAction
  ) {
    return this.loginService.authinticateCreator(payload).pipe(
      tap((res: HttpResponse<SignResponse>) => {
        if (res.status === 200) {
          let response = res.body as SignResponse
          const tokenBody: TokenBody = jwt_decode(response.access)
          ctx.setState({
            access: res.body?.access,
            refresh: res.body?.refresh,
            tokenBody: tokenBody,
          });
          this.mixpanelService.identify(""+tokenBody.creator_id)
          this.mixpanelService.set({
            first_name: tokenBody.first_name,
            last_name: tokenBody.last_name
          });
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @Action(RefreshTokenAction)
  public refreshToken(
    ctx: StateContext<LoginStateModel>,
    { access }: LoginStateModel
  ) {
    ctx.patchState({ access });
  }

  @Action(LoginRegisteredUser)
  public loginRegisteredUser(
    ctx: StateContext<LoginStateModel>,
    { access, refresh }: LoginRegisteredUser
  ) {
    const tokenBody: TokenBody = jwt_decode(access) as TokenBody;
    return ctx.patchState({
      access,
      refresh,
      tokenBody,
    });
  }

  @Action(LogoutAction)
  public logoutUser(ctx: StateContext<LoginStateModel>, { }: LogoutAction) {
    sessionStorage.clear();
    ctx.setState({
      access: null,
      refresh: null,
      tokenBody: null,
    });
  }

  @Action(AuthorizeOTPSubmissionRequest)
  public resetPassword(
    ctx: StateContext<LoginStateModel>,
    { code }: AuthorizeOTPSubmissionRequest
  ) {
    return code;
  }

  @Action(SignupAction)
  public signup(
    ctx: StateContext<LoginStateModel>,
    { payload }: SignupAction
  ) {
    return this.signupService.signup(payload).pipe(
      tap((res) => {
      }),
      catchError((err) => {
        throw err
      })
    );
  }
}
