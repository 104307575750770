import { Component } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { MixpanelService } from './shared/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'creator-portal-ui';

  constructor (
    private gtmService: GoogleTagManagerService,
    private mixpanelService: MixpanelService,
  ) {
    if(environment.googleTagID !== "unsupported") {
      this.gtmService.addGtmToDom();
    }
    this.mixpanelService.init(environment.mixpanelKey)
  }
}
