import { LoginRequest } from "src/app/shared/models/sign.model";

export enum LoginActionTypes{
  Authenticate="[Auth] Authenticate Creator",
  Refresh="[Refresh] Refresh Creator",
  AuthenticateFireBaseUser="[Auth] Authenticate firebase user",
  Logout="[Logout] Clear User Data on logout",
  ResetPassword = "[Auth] Reset Password Action"
}


export class LoginAction {
  public static readonly type = LoginActionTypes.Authenticate;
  constructor(public payload: LoginRequest) {}
}

export class RefreshTokenAction {
  public static readonly type = LoginActionTypes.Refresh;
  constructor(public access: string) {}
}

export class LoginRegisteredUser {
  public static readonly type = LoginActionTypes.AuthenticateFireBaseUser;
  constructor(public access: string, public refresh: string) {}
}

export class LogoutAction{
  public static readonly type = LoginActionTypes.Logout;
  constructor() {}

}

export class AuthorizeOTPSubmissionRequest {
  public static readonly type = LoginActionTypes.ResetPassword;
  constructor(public code: string) {}
}

