
<header class="header">
  <button mat-icon-button id="toggle-sidenav-button" color="transparent" (click)="sidenav.toggle()" *ngIf="!isDesktop"><mat-icon svgIcon="side-nav-bar"></mat-icon></button>
  <div class="logo" *ngIf="!isDesktop">shop Real</div>
  <div class="extra" *ngIf="!isDesktop"></div>
  <section class="navigation-section" *ngIf="isDesktop">
    <button routerLink="home" id="navigato-to-home-button" routerLinkActive="active" class="nav-btn" (click)="products_active = true; favorites_active = false; analytics_active = false">
      <img src="../../../assets/icons/Product-icon.svg" alt=""  *ngIf="!products_active"/>
      <img src="../../../assets/icons/Product-icon-after-click-header.svg" alt=""  *ngIf="products_active"/>
      Products
    </button>
    <button routerLink="favorites" id="navigato-to-favorites-button" routerLinkActive="active" class="nav-btn" (click)="products_active = false; favorites_active = true; analytics_active = false" *ngIf="false">
      <img src="../../../assets/icons/Heart-icon.svg" alt="" />Favorites
    </button>
    <button routerLink="analytics" id="navigato-to-analytics-button" routerLinkActive="active" class="nav-btn" (click)="products_active = false; favorites_active = false; analytics_active = true">
      <img src="../../../assets/icons/Analytics-icon.svg" alt="" *ngIf="!analytics_active" />
      <img src="../../../assets/icons/Analytics-icon-after-click-header.svg" alt="" *ngIf="analytics_active" />
      Analytics
    </button>
  </section>
  <section class="search-menu-section" *ngIf="isDesktop">
    <section class="menu-section" *ngIf="isDesktop">
      <img src="../../../assets/images/img_avatar.png" alt="Avatar" class="avatar" />
      <button [matMenuTriggerFor]="menu" id="username-button" class = "user-name-button">{{userName}} <mat-icon svgIcon="down-arrow"> </mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item id="logout-button" (click)="logout()"> <mat-icon svgIcon="logout"> </mat-icon>Logout</button>
      </mat-menu>
    </section>
  </section>
</header>

<mat-sidenav-container class="sidenav-wrapper">
  <mat-sidenav #sidenav mode="over">
    <a [routerLink]="['home']" [routerLinkActive]="['active']">
      <button mat-button routerLinkActive="activebutton" (click)="sidenav.toggle();products_active = true; favorites_active = false; analytics_active = false" class="sidenav-button">
        <mat-icon svgIcon="products"  *ngIf="!products_active"></mat-icon>
        <mat-icon svgIcon="products-after-click-sidebar"  *ngIf="products_active"></mat-icon>
        <span>Products</span>
      </button>
    </a>
    <a [routerLink]="['favorites']" [routerLinkActive]="['active']" *ngIf="false">
      <button mat-button routerLinkActive="activebutton" (click)="sidenav.toggle();products_active = false; favorites_active = true; analytics_active = false" class="sidenav-button">
        <mat-icon svgIcon="favorites"></mat-icon>
        <span>Favorites</span>
      </button>
    </a>
    <a [routerLink]="['analytics']" [routerLinkActive]="['active']">
      <button mat-button routerLinkActive="activebutton" (click)="sidenav.toggle() ;products_active = false; favorites_active = false; analytics_active = true" class="sidenav-button">
        <mat-icon svgIcon="analytics" *ngIf="!analytics_active"></mat-icon>
        <mat-icon svgIcon="analytics-after-click-sidebar" *ngIf="analytics_active"></mat-icon>
        <span>Analytics</span>
      </button>
    </a>

    <a>
      <button mat-button routerLinkActive="activebutton" (click)="logout()" class="sidenav-button">
        <mat-icon svgIcon="logout"></mat-icon>
        <span>Logout</span>
      </button>
    </a>

  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

